input[type='date']::before {
  content: attr(placeholder) ' : ';
  white-space: pre;
  font-size: 8px;
}
input[type='date']:focus::before {
  content: '' !important;
}

.is-required::after {
  content: ' *';
  color: red;
  font-weight: normal;
}

.spinner {
  position: relative;
  top: 1px;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.spinner-icon {
  width: 15px;
  height: 15px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;

  animation: spin 400ms linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-white {
  border-top-color: #fff;
  border-left-color: #fff;
}
.h-100p {
  height: 100%;
}
.p-0 {
  padding: 0;
}
.pl-0 {
  padding-left: 0;
}
