.h-100vh {
  height: 100vh;
}

.flex {
  display: flex;
  flex-direction: row;
}

.flex-h-centered {
  justify-content: center;
}

.flex-v-centered {
  align-items: center;
}

.cross {
  position: relative;
  margin-right: 5px;
  height: 16px; /* this can be anything */
  width: 16px; /* ...but maintain 1:1 aspect ratio */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cross::before,
.cross::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 3px; /* cross thickness */
  background-color: red;
}

.cross::before {
  transform: rotate(45deg);
}

.cross::after {
  transform: rotate(-45deg);
}

.check {
  display: inline-block;
  margin-right: 10px;
  height: 25px; /* this can be anything */
  width: 12px; /* ...but maintain 1:1 aspect ratio */
  transform: rotate(45deg);
  border-bottom: 4px solid green;
  border-right: 4px solid green;
}
