.app-header {
  height: auto;
}

.app-header-nav {
  max-height: 60px !important;
  min-height: 60px !important;
}
.app-header-user {
  /* SVP : Évitez le plus possible d'utiliser la notation "!important". Ici je n'avais pas le choix hélas. */
  max-width: 320px !important;
}

.navbar-text-size-fix {
  height: auto !important;
  /* min-height: 100% !important; */
  text-align: center;
}

.navbar-logo {
  width: 110px;
}
